.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.highlighted {
  font-weight: bold;
  /* Add any other specific styles you want */
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'QuanZiKuShuoWenJieZi';
  src: url('https://iching-tarot-asset.s3.ap-east-1.amazonaws.com/iching-books/web/QuanZiKuShuoWenJieZi.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FangZhengJiaGuWen';
  src: url('https://iching-tarot-asset.s3.ap-east-1.amazonaws.com/iching-books/web/FangZhengJiaGuWen.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


.shuoWen-font {
  font-family: 'QuanZiKuShuoWenJieZi','Noto Sans TC', sans-serif;
  font-size: 72px;
}


.jiaGuWen-font {
  font-family: 'FangZhengJiaGuWen','Noto Sans TC', sans-serif;
  font-size: 72px;
}

body {
    font-family: 'Noto Sans TC', sans-serif;
}

h3,h4,p {
  margin-top:0px;
  margin-bottom: 10px;
}

p{
  margin-top:0px;
  margin-bottom: 2px;
  font-size: 15px;
  text-align: left;
}

h5 {
  margin-top:0px;
  margin-bottom: 10px;
}

.lsIsContainer {
  display: block;
  text-align: left;
}

.lsItem, .isItem {
  display: inline;
  margin-right: 5px;
}

.isItem {
  font-size: 12px;
}

/* Scrollbar container */
::-webkit-scrollbar {
  width: 6px; 
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}

/* Scrollbar handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}